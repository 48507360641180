
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, VModel, Vue } from "vue-property-decorator";
import { CreateUser } from "@/services/UserServices";

@Component
export default class NewAccount extends Vue {
  @VModel({ required: true }) show!: boolean;

  inputs = {
    name: "",
    email: "",
    password: "",
    passwordRepeat: "",
    admin: false,
  };

  loading = {
    submit: false,
  };

  async submit() {
    try {
      this.loading.submit = true;
      await CreateUser(
        this.inputs.name,
        this.inputs.email,
        this.inputs.password,
        this.inputs.admin
      );
      this.loading.submit = false;
      this.show = false;
      this.$emit("refresh");
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "black",
        text: "Gebruiker toegevoegd",
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: "error",
      });
    } finally {
      this.loading.submit = false;
    }
  }
}
